
import { defineComponent, inject, ref } from "vue";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { Artist } from "../main";
import { CachePolicy } from "vuebase/src/storage";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "Artist",
    setup() {
        const database = inject(Database.InjectionKey);
        const imagePicker = ref();
        const artist = ref<Database.Document<Artist>>();

        if (useRoute().params.id as string) {
            artist.value = database?.collection<Artist>("artists").document(useRoute().params.id as string);
        } else {
            artist.value = database?.collection<Artist>("artists").new(undefined, {
                profilePic: undefined,
                email: "",
                name: "",
                location: "",
                bio: "",
            });
        }

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "artists/" + artist.value?.id,
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function saveArtist(): Promise<void> {
            if (artist.value) {
                imagePicker.value.applyCrop();
                return artist.value.save();
            }
            return Promise.reject();
        }

        function deleteArtist(): Promise<void> {
            if (artist.value) {
                const confirm = window.confirm("Are you sure you want to delete this artist?");
                if (confirm) {
                    return artist.value.delete();
                }
            }
            return Promise.reject();
        }

        return {
            artist,
            imagePicker,
            uploadImage,
            saveArtist,
            deleteArtist,
        };
    },
});
