<template>
    <c-panel>
        <c-grid class="widths-50-all" v-if="artist && artist.data">
            <c-grid-item>
                <c-image-picker
                    ref="imagePicker"
                    v-model="artist.data.profilePic"
                    :initialImage="artist.data.profilePic"
                    :aspectRatio="1"
                    :upload="uploadImage"
                ></c-image-picker>
            </c-grid-item>
            <c-grid-item>
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <c-textbox label="Name" v-model="artist.data.name" />
                    </c-grid-item>
                    <c-grid-item>
                        <c-textbox label="Email" v-model="artist.data.email" />
                    </c-grid-item>
                    <c-grid-item>
                        <c-textbox label="Location" v-model="artist.data.location" />
                    </c-grid-item>
                    <c-grid-item>
                        <c-textarea label="Bio" :lines="5" v-model="artist.data.bio" />
                    </c-grid-item>
                    <c-grid-item>
                        <c-button-group class="flex f-justify-end">
                            <c-button class="mt-5" @click="saveArtist()">Save</c-button>
                            <c-button class="mt-5 red" @click="deleteArtist()">Delete</c-button>
                        </c-button-group>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from "vue";
import { ImageData } from "coho-ui";
import server from "../server";
import { Database } from "vuebase";
import { Artist } from "../main";
import { CachePolicy } from "vuebase/src/storage";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "Artist",
    setup() {
        const database = inject(Database.InjectionKey);
        const imagePicker = ref();
        const artist = ref<Database.Document<Artist>>();

        if (useRoute().params.id as string) {
            artist.value = database?.collection<Artist>("artists").document(useRoute().params.id as string);
        } else {
            artist.value = database?.collection<Artist>("artists").new(undefined, {
                profilePic: undefined,
                email: "",
                name: "",
                location: "",
                bio: "",
            });
        }

        function uploadImage(file: File, progressCallback: (progress: number) => void): Promise<ImageData | void> {
            return server.uploadImage(
                file,
                "artists/" + artist.value?.id,
                progressCallback,
                CachePolicy.Day,
                { width: 1200, height: 1200 },
                100,
            );
        }

        function saveArtist(): Promise<void> {
            if (artist.value) {
                imagePicker.value.applyCrop();
                return artist.value.save();
            }
            return Promise.reject();
        }

        function deleteArtist(): Promise<void> {
            if (artist.value) {
                const confirm = window.confirm("Are you sure you want to delete this artist?");
                if (confirm) {
                    return artist.value.delete();
                }
            }
            return Promise.reject();
        }

        return {
            artist,
            imagePicker,
            uploadImage,
            saveArtist,
            deleteArtist,
        };
    },
});
</script>

<style lang="scss">
@import "../../../coho-ui/src/styles/variables.scss";

.success {
    background-color: $color-green;
    color: white;
    padding: 24px;
    border-radius: $border-radius;
    box-shadow: $shadow-sm;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
}
</style>
